const jquery = require('./jquery-2.1.0.min');
//
$ = window.$ = window.jQuery = jquery;

require('./popper.js');
require('./bootstrap.min.js');

require('./scrollreveal.min.js');
require('jquery-waypoints/waypoints');
require('./jquery.counterup.min.js');
require('./imgfix.min.js');

require('./imgfix.min');

let initial = true;
let handleScroll = true;

/** `end` time variable */
const END_TIME = '9 November 2021 07:00:00 GMT+01:00';

(function ($) {
  'use strict';
  makeTimer();

  animateNav();

  // Window Resize Mobile Menu Fix
  mobileNav();

  setPodrskaImg();

  // Scroll animation init
  window.sr = new scrollReveal();

  // Menu Dropdown Toggle
  if ($('.menu-trigger').length) {
    $('.menu-trigger').on('click', function () {
      $(this).toggleClass('active');
      $('.header-area .nav').slideToggle(200);
    });
  }

  function animateNav() {
    if ($(window).scrollTop() > 100) {
      $('.header-area').addClass('header-area-top');
      $('.header-area div.container').addClass('all-container');
      $('.header-area div.container .col-12').addClass('col-12-mob');
      $('.header-area div.container .col-12 .main-nav').addClass('nav-anim');
      $('.header-area .main-nav .logo .logo-normal').addClass('show-logo');
      $('.header-area .main-nav').removeClass('centered-nav');
      $('.main-nav .logo-mobile').addClass('logo-hidden');
      $('.header-area .main-nav .logo-normal').removeClass('logo-hidden');
    } else {
      $('.header-area').removeClass('header-area-top');
      $('.header-area div.container').removeClass('all-container');
      $('.header-area div.container .col-12').removeClass('col-12-mob');
      $('.header-area div.container .col-12 .main-nav').removeClass('nav-anim');
      $('.header-area .main-nav .logo .logo-normal').removeClass('show-logo');
      $('.header-area .main-nav').addClass('centered-nav');
      $('.main-nav .logo-mobile').removeClass('logo-hidden');
      $('.header-area .main-nav .logo-normal').addClass('logo-hidden');
    }
  }

  // Menu elevator animation
  $('a[href*=\\#]:not([href=\\#])').on('click', function () {
    $('.nav li a').each((_, a) => $(a).removeClass('active'));
    $(this).addClass('active');
    handleScroll = false;
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        var width = $(window).width();
        if (width < 991) {
          $('.menu-trigger').removeClass('active');
          $('.header-area .nav').slideUp(200);
        }
        $('html,body').animate(
          {
            scrollTop: target.offset().top - 130,
          },
          {
            duration: 700,
          }
        );
        setTimeout(animateNav, 500);
        setTimeout(() => {
          handleScroll = true;
        }, 1000);
        return false;
      }
    }
    setTimeout(() => (handleScroll = true), 1000);
  });

  $(document).ready(function () {
    $(document).on('scroll', onScroll);

    //smoothscroll
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
      $(document).off('scroll');

      $('a').each(function () {
        $(this).removeClass('active');
      });
      $(this).addClass('active');

      var target = this.hash,
        menu = target;
      var target = $(this.hash);
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: target.offset().top - 130,
          },
          500,
          'swing',
          function () {
            window.location.hash = target;
            $(document).on('scroll', onScroll);
          }
        );
    });
  });

  function onScroll(event) {
    if (handleScroll) {
      var scrollPos = $(document).scrollTop();
      $('.nav a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr('href'));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $('.nav ul li a').removeClass('active');
          currLink.addClass('active');
        } else {
          currLink.removeClass('active');
        }
      });
    }
    animateNav();
  }

  // Home seperator
  if ($('.home-seperator').length) {
    $('.home-seperator .left-item, .home-seperator .right-item').imgfix();
  }

  // Home number counterup
  if ($('.features-small-item').length) {
    $('.features-small-item .ucesnici-broj').counterUp({
      delay: 10,
      time: 2000,
    });
  }

  // Home number counterup
  if ($('.count-item').length) {
    $('.count-item strong').counterUp({
      delay: 10,
      time: 700,
    });
  }

  // Page loading animation
  $(window).on('load', function () {
    if ($('.cover').length) {
      $('.cover').parallax({
        imageSrc: $('.cover').data('image'),
        zIndex: '1',
      });
    }

    $('#preloader').animate(
      {
        opacity: '0',
      },
      600,
      function () {
        setTimeout(function () {
          $('#preloader').css('visibility', 'hidden').fadeOut();
        }, 300);
      }
    );
  });

  // Window Resize Mobile Menu Fix
  $(window).on('resize', function () {
    mobileNav();
    setPodrskaImg();
  });

  // Window Resize Mobile Menu Fix
  function mobileNav() {
    var width = $(window).width();
    $('.submenu').on('click', function () {
      if (width < 992) {
        $('.submenu ul').removeClass('active');
        $(this).find('ul').toggleClass('active');
      }
    });
  }

  function setPodrskaImg() {
    var width = $(window).width();
    if (width < 1212) {
      $('#podrska-img-desktop').addClass('logo-hidden');
      $('#podrska-img-mobile').removeClass('logo-hidden');
    } else {
      $('#podrska-img-desktop').removeClass('logo-hidden');
      $('#podrska-img-mobile').addClass('logo-hidden');
    }
  }

  function makeTimer() {
    var endTime = new Date(END_TIME);
    endTime = Date.parse(endTime) / 1000;

    var now = new Date();
    now = Date.parse(now) / 1000;

    var timeLeft = endTime - now;
    if (initial) timeLeft = timeLeft - 1;
    initial = false;

    var days = Math.floor(timeLeft / 86400);
    var hours = Math.floor((timeLeft - days * 86400) / 3600);
    var minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    var seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );

    if (hours < '10') {
      hours = '0' + hours;
    }
    if (minutes < '10') {
      minutes = '0' + minutes;
    }
    if (seconds < '10') {
      seconds = '0' + seconds;
    }

    // case when days are negative
    if(days < 0) {
      hours = '0';
      minutes = '0';
      seconds = '0';
      days = '0';
    }

    $('#days').html(parseInt(days).toFixed(0));
    $('#hours').html(parseInt(hours).toFixed(0));
    $('#minutes').html(parseInt(minutes).toFixed(0));
    $('#seconds').html(parseInt(seconds).toFixed(0));
  }
  setInterval(makeTimer, 1000);
})(window.jQuery);
